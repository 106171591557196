.map-section {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--main-color);
  margin-top: 100px;
  padding: 50px 0;
  overflow: hidden;
}

.map-section h1 {
  font-size: 40px;
  font-weight: 600;
  color: white;
  margin-bottom: 50px;
  z-index: 1;
  text-align: center;
}

.map-section img {
  position: absolute;
  top: -10%;
  right: -30%;
  width: 70%;
  filter: brightness(0);
  opacity: 0.3;
}

.container {
  display: flex;
  max-width: 100%;
  justify-content: space-evenly;
  align-items: center;
}

.yandexMap {
  margin: 30px;
  width: 45%;
  height: 550px;
  overflow: hidden;
  position: relative;
  filter: drop-shadow(10px 10px var(--bg-color));
}

.yandexTestimonials {
  margin: 30px;
  width: 25%;
  height: 650px;
  overflow: hidden;
  position: relative;
  filter: drop-shadow(10px 10px var(--bg-color));
}

.yandexIframe {
  border-radius: 15px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.yandexATag {
  box-sizing: border-box;
  text-decoration: none;
  color: #b3b3b3;
  font-size: 10px;
  font-family: YS Text, sans-serif;
  padding: 0 20px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
  left: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  max-height: 14px;
  white-space: nowrap;
  padding: 0 16px;
  box-sizing: border-box;
}

@media screen and (max-width: 1280px) {
  .container {
    flex-direction: column;
  }

  .yandexMap {
    width: 80%;
  }

  .yandexTestimonials {
    width: 40%;
  }
}

@media screen and (max-width: 768px) {
  .yandexTestimonials {
    width: 70%;
  }
}

@media screen and (max-width: 425px) {
  .yandexMap {
    width: 85%;
  }

  .yandexTestimonials {
    width: 90%;
  }
}