.gallery {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--main-color);
    color: white;

    margin: 70px 0;
    padding: 50px 0;
}

.gallery h1 {
    font-size: 60px;
    font-weight: 600;
    margin-bottom: 30px;
}

.gallery h3 {
    font-size: 24px;
    margin-bottom: 50px;
}

.gallery button {
    margin: 30px 0;
    border: 2px solid var(--bg-color-dark);
    border-radius: 10px;
    background-color: transparent;
    font-size: 32px;
    font-weight: 600;
    color: var(--bg-color-dark);
}

.gallery button:hover {
    transform: translateY(-5px);
    border: 2px solid transparent;
}

.photos-container {
    width: 90%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 20px;
    row-gap: 40px;
}

.photos-container div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.photos-container div img {
    width: 85%;
    height: auto;
    box-shadow: 0 0 5px black;
}

.photos-container div img:hover {
    transform: scale(1.03);
    box-shadow: 0 0 15px black;
}

.single-photo-menu {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.single-photo-menu .arrow {
    height: 100%;
    padding: 0 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 36px;
    z-index: 103;
    background-color: rgba(0, 0, 0, 0.4);
}

.single-photo-menu .arrow:hover {
    background-color: rgba(0, 0, 0, 0.6);
}

.single-photo {
    width: 35%;
    z-index: 104;
    display: flex;
    justify-content: center;

}

.single-photo-mod-hider {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 768px) {
    .photos-container {
        grid-template-columns: repeat(2, 1fr);
    }

    .single-photo {
        width: 50%;
    }

    .single-photo img {
        width: 100%;
    }

    .single-photo-menu .arrow {
        padding: 0 20px;
    }
}

@media screen and (max-width: 425px) {
    .gallery h1 {
        font-size: 36px;
    }

    .gallery h3 {
        font-size: 18px;
        text-align: center;
    }

    .single-photo {
        width: 60%;
    }
}