@font-face {
    font-family: "SF Pro Display"; /* Имя шрифта */
    font-weight: 400;
    src: url(SF-Pro-Display-Regular.otf); /* Путь к файлу со шрифтом */
}
@font-face {
    font-family: "SF Pro Display"; /* Имя шрифта */
    font-weight: 500;
    src: url(SF-Pro-Display-Medium.otf); /* Путь к файлу со шрифтом */
}
@font-face {
    font-family: "SF Pro Display"; /* Имя шрифта */
    font-weight: 600;
    src: url(SF-Pro-Display-Semibold.otf); /* Путь к файлу со шрифтом */
}
@font-face {
    font-family: "SF Pro Display"; /* Имя шрифта */
    font-weight: 700;
    src: url(SF-Pro-Display-Bold.otf); /* Путь к файлу со шрифтом */
}
@font-face {
    font-family: "SF Pro Display"; /* Имя шрифта */
    font-weight: 800;
    src: url(SF-Pro-Display-Heavy.otf); /* Путь к файлу со шрифтом */
}
@font-face {
    font-family: "SF Pro Display"; /* Имя шрифта */
    font-weight: 900;
    src: url(SFProDisplay-Black.ttf); /* Путь к файлу со шрифтом */
}