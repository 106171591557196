.intro-section {
  margin: 100px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.intro-section h1 {
  color: white;
  font-size: 50px;
  font-weight: 600;
  text-align: center;
}

.intro-section span {
  color: white;
  font-size: 20px;
  margin-top: 30px;
  margin-bottom: 80px;
  text-align: center;
}

.before-after-images {
  width: 80%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-items: center;
  row-gap: 100px;
}

@media screen and (max-width: 425px) {
  .before-after-images {
    width: 90%;
    row-gap: 50px;

  }  
}