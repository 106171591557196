.ba {
    position: relative;
    height: 300px;
    width: 300px;
    display: flex;
    justify-content: center;
}

.ba .pic {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: var(--main-color);
    border-radius: 50%;
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    border: 5px solid var(--main-color);
}

.ba img {
    position: absolute;
    width: 100%;
}

.ba img:nth-child(1) {
    z-index: 1;
}

.pic-desc {
    position: absolute;
    text-align: center;
    top: 10px;
    font-size: 22px;
    width: 30%;
    padding: 5px;
    border-radius: 3px;
    background-color: var(--main-color);
    color: var(--bg-color);
    transition: 0.60s;
}

.curr-desc {
    top: -35px !important;
}

.pic-toggler {
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0);
}

.pic-toggler:hover {
    background-color: rgba(0, 0, 0, 0.6);
}

.pic-toggler img {
    opacity: 0;
    transform: scale(0.4) rotateZ(-90deg);
    height: 80%;
    width: 80%;
    z-index: -10;
}

.pic-toggler:hover img {
    transform: scale(1) rotateZ(0);
    opacity: 1;
}

.pic-toggler-flash {
    animation: 0.6s bgFlash linear;
}

.hide-clock {
    pointer-events: none;
    animation: 0.3s clockHiding ease-in-out;
}

.hide-clock-quickly {
    animation: 0.2s clockHidingQuickly ease-in-out;
}

.show-clock {
    animation: 0.5s clockShowing ease-in-out;
}

@keyframes clockHiding {
    from {
        transform: scale(1) rotateZ(0);
        opacity: 1;
    }
    to {
        transform: scale(0.4) rotateZ(90deg);
        opacity: 0;
    }
}

@keyframes clockHidingQuickly {
    from {
        transform: scale(1);
        opacity: 1;
    }
    to {
        transform: scale(0.4);
        opacity: 0;
    }
}

@keyframes clockShowing {
    from {
        transform: scale(0.4) rotateZ(-90deg);
        opacity: 0;
    }
    to {
        transform: scale(1) rotateZ(0);
        opacity: 1;
    }
}

@keyframes bgFlash {
    from {
        background-color: rgba(0, 0, 0, 0.6);
    }
    25% {
        background-color: rgba(0, 0, 0, 1);
    }
    75% {
        background-color: rgba(0, 0, 0, 1);
    }
    to {
        background-color: rgba(0, 0, 0, 0.6);
    }
}

@media screen and (max-width: 768px) {
    .ba {
        width: 200px;
        height: 200px;
    }

    .pic-desc {
        font-size: 16px;
    }

    .curr-desc {
        top: -25px !important;
    }
}

@media screen and (max-width: 576px) {
    .ba {
        width: 150px;
        height: 150px;
    }

    .pic-desc {
        font-size: 12px;
    }

    .curr-desc {
        top: -20px !important;
    }
}

@media screen and (max-width: 320px) {
    .ba {
        width: 120px;
        height: 120px;
    }

    .ba .pic {
        border: 3px solid var(--main-color);
    }

    .pic-desc {
        width: 40%;
    }

    .curr-desc {
        top: -20px !important;
    }
}