.popup-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.3);
    display: block;
    z-index: 900;
    overflow-y: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.popup-hide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.popup {
    position: absolute;
    max-width: 70%;
    background: rgb(172, 210, 172);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 901;

    animation: showPopup 0.25s ease-in-out;
}

@keyframes showPopup {
    from {
        transform: translateY(200px) scale(0.3);
        opacity: 0;
    }
    to {
        transform: translateY(0) scale(1);
        opacity: 1;
    }
}

.hidden-popup {
    display: none;
}

.phoneTitle{
    font-size: 24px;
    text-align: center;
}

.phoneNumber{
    font-size: 28px;
    text-align: center;
}

.popup button{
    transition: 0.3s;
    -webkit-appearance: none;
    background-color: var(--main-color);
    color: white;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    border: none;
    border-radius: 100px;
    padding: 15px;
    margin: 50px;
    margin-top: 0;
    font-family: "SF Pro Display","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;
}

.popup button:hover {
    background-color: var(--main-color-dark);
}

.close-popup {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.close-popup:hover {
    transform: scale(0.9);
}

.no-scroll {
    overflow-y: hidden;
}

/* different popups */

.good-popup .popup-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 50px;
}

.good-popup .popup-content .upper {
    display: flex;
    margin-bottom: 50px;
}

.good-popup .upper div {
    display: flex;
    align-items: center;
}
  
.good-popup .popup-img {
    width: 40%;
    height: auto;
    border-radius: 10px;
    margin-right: 50px;
    filter: drop-shadow(0 0 20px rgba(0, 0, 0, 0.5));
}

.good-popup h2 {
    font-size: 32px;
    color:var(--bg-color);
    margin-bottom: 50px;
}

.good-popup h4 {
    font-size: 24px;
    color:var(--bg-color);
    text-align: center;
}

.call-popup .popup-content {
    margin: 40px 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.call-popup button {
    min-width: 300px;
}

@media screen and (min-width: 1280px) {
    .good-popup h2 {
        font-size: 42px;
    }
}

@media screen and (max-width: 768px) {
    .good-popup h2 {
        font-size: 28px;
        text-align: center;
        margin-bottom: 30px;
    }

    .good-popup h4 {
        font-size: 18px;
    }

    .popup {
        max-width: 80%;
    }

    .good-popup .popup-content .upper {
        align-items: center;
        flex-direction: column;
        margin: 0;
    }

    .good-popup .upper img {
        margin: 0;
        width: 50%;
        margin-bottom: 20px;
    }

    .good-popup .popup-content {
        margin: 30px;
    }
}

@media screen and (max-width: 492px) {
    .popup button{
        font-size: 18px;
    }

    .good-popup h2 {
        font-size: 24px;
    }

    .good-popup h4 {
        font-size: 16px;
    }
}

@media screen and (max-width: 492px) {
    .good-popup h2 {
        font-size: 20px;
    }

    .good-popup h4 {
        font-size: 12px;
    }

    .popup button{
        padding: 10px;
        font-size: 14px;
        margin: 30px;
        margin-top: 0;
    }
}