.goods-section {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.goods-section h1 {
  font-size: 60px;
  font-weight: 700;
  margin-bottom: 50px;
}

.goods-block {
  width: 80%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.good-item {
  position: relative;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 7px 12px 25px rgba(0, 0, 0, 0.16);
  padding: 10px;
  overflow: hidden;

  border: none;
  margin-top: 50px;
  margin-right: 20px;
  background-color: var(--main-color);
}

.good-item:hover {
  transform: translateY(-5px);
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.5);
}

.good-item img {
  width: 90%;
  margin: 10px;
  border-radius: 10px 10px 0 0;
  z-index: 2;
}

.good-bg-logo {
  width: 50% !important;
  position: absolute;
  bottom: 3%;

  filter: brightness(0);
  opacity: 0.15;
}

.good-item .info {
  height: 150px;
  width: 90%;
  font-size: 18px;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  text-align: center;
  z-index: 2;
}

.good-item .info button {
  height: 40px;
  background-color: var(--main-color-dark);
  font-size: 24px;
  color: white;
  border: none;
  border-radius: 10px;
}

.good-item .info button:hover {
  filter: brightness(90%);
}

@media screen and (max-width: 1080px) {
  .goods-block {
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
  }
}

@media screen and (max-width: 580px) {
  .goods-block {
    width: 90%;
    gap: 20px;
  }

  .good-item {
    width: 100%;
    height: 90%;
  }
}

@media screen and (max-width: 425px) {
  .goods-section h1 {
    font-size: 32px;
  }

  .good-item {
    margin: 10px 0;
    height: 75%;
  }

  .good-item .info {
    font-size: 12px;
  }

  .good-item .info button {
    font-size: 16px;
  }
}