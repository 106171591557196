.navbar {
  width: 100%;
  height: 80px;
  margin-bottom: 100px;
  display: flex;
  justify-content: center;
}

.navbar > div {
  top: 30px;
  width: 80%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 15px;
}

.nav-logo {
  background-color: var(--main-color);
  border-radius: 50px;
  padding: 10px;
}

.navbar img {
  width: 110px;
  height: 110px;
}

.navbar h3 {
  font-family: "bernard-mt";
  font-size: 15px;
  color: var(--bg-color);
}

.fixed-nav {
  top: -150px;
  width: 100%;
  height: 110px;
  margin-bottom: 100px;
  display: flex;
  justify-content: center;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
  z-index: 100;
  border-bottom: 4px solid var(--main-color);
}

.fixed-nav > div {
  width: 80%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 15px;
}

.fix-nav-logo {
  background-color: var(--main-color);
  border-radius: 45px;
  padding: 10px;
}

.fixed-nav img {
  width: 80px;
  height: 80px;
}

.nav-content {
  height: 100%;
  display: flex;
  align-items: center;
}

.nav-content > div {
  margin: 0 15px;
}

.nav-options {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.nav-option {
  height: 100%;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 20px;
  font-weight: 400;
  color: white;
  transition: 0.25s;
  cursor: pointer;

}

.nav-option .underline {
  margin-top: 2px;
  height: 2px;
  width: 0px;
  background-color: white;
}

.nav-option:hover .underline {
  width: 100%;
}

.nav-links {
  display: flex;
  align-items: center;
}

.nav-links a {
  margin: 0 5px;
  filter: invert(1);
}

.nav-links img {
  height: 40px;
  width: 40px;
}

.nav-links a:hover {
  filter: invert(1) brightness(80%);
}

.burger {
  width: 35px;
  height: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.burger div {
  height: 15%;
  width: 100%;
  border-radius: 3px;
  background-color: white;
}

.phone-nav-options {
  position: fixed;
  top: 0;
  left: 0;
  margin: 0 !important;
  padding: 20px;
  width: 100%;
  height: 100%;
  display: none;
  flex-direction: column;
  align-items: center;
  background-color: var(--bg-color);
  z-index: 300;
}

.phone-nav-options .nav-option {
  text-align: center;
  align-items: center;
  font-size: 40px;
}

.phone-nav-options .line {
  width: 100%;
  height: 2px;
  background-color: var(--main-color);
}

.phone-nav-options .close-popup {
  width: 50px;
  height: 50px;
  right: 35px;
  top: 45px;
  filter: invert(1);
}

.phone-nav-appear {
  animation: appear 0.2s linear;
}

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media screen and (max-width: 576px) {
  .phone-nav-options .nav-option {
    font-size: 28px;
  }
  .nav-logo, .fix-nav-logo {
  background-color: transparent;
  }
  .fixed-nav {
    height: 89px;
  }
  .navbar img {
    width: 80px;
    height: 80px;
  }


}
/*@supports  (backdrop-filter: blur(10px)) {*/
/*  .fixed-nav {*/
/*    background-color: #27312c;*/
/*    -webkit-backdrop-filter: none;*/
/*    backdrop-filter: none;*/
/*    z-index: 100;*/
/*    border-bottom: 4px solid var(--main-color);*/
/*  }*/
/*}*/