@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap");

@font-face {
  font-family: "bernard-mt";
  src: url("../../fonts/bernard-mt-condensed-webfont.woff2") format("woff2"),
    url("../../fonts/bernard-mt-condensed-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "SF Pro Display"; /* Имя шрифта */
  font-weight: 400;
  src: url(../../fonts/SF-Pro-Display-Regular.otf); /* Путь к файлу со шрифтом */
}
@font-face {
  font-family: "SF Pro Display"; /* Имя шрифта */
  font-weight: 500;
  src: url(../../fonts/SF-Pro-Display-Medium.otf); /* Путь к файлу со шрифтом */
}
@font-face {
  font-family: "SF Pro Display"; /* Имя шрифта */
  font-weight: 600;
  src: url(../../fonts/SF-Pro-Display-Semibold.otf); /* Путь к файлу со шрифтом */
}
@font-face {
  font-family: "SF Pro Display"; /* Имя шрифта */
  font-weight: 700;
  src: url(../../fonts/SF-Pro-Display-Bold.otf); /* Путь к файлу со шрифтом */
}
@font-face {
  font-family: "SF Pro Display"; /* Имя шрифта */
  font-weight: 800;
  src: url(../../fonts/SF-Pro-Display-Heavy.otf); /* Путь к файлу со шрифтом */
}
@font-face {
  font-family: "SF Pro Display"; /* Имя шрифта */
  font-weight: 900;
  src: url(../../fonts/SFProDisplay-Black.ttf); /* Путь к файлу со шрифтом */
}
::selection {
  background: #DCE35B; /* Safari */
}
::-moz-selection {
  background: #DCE35B; /* Firefox */
}
* {
  margin: 0;
  font-family: "Roboto";
  transition: 0.25s;
}

* button {
  cursor: pointer;
}

* img {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;      
  -ms-user-select: none;
  user-select: none;           
}

:root {
  --main-color: #54955e;
  --main-color-dark: #3d6e42;
  --bg-color: #27312c;
  --bg-color-dark: #232a27;
}

body,
html{
  background-color: var(--bg-color);
}

@keyframes coolspawn {
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

header {
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 100px;
}

.hero {
  width: 75%;
  height: 500px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hero-circle:nth-child(2) {
  position: absolute;
  left: 60%;
  top: 35%;
  height: 30vw;
  width: 30vw;
  background: var(--main-color);
  border-radius: 50%;
  z-index: 0;
  animation: 0.6s coolspawn ease-in-out;
}

.hero-circle img {
  position: absolute;
  bottom: 10%;
  right: 20%;
  height: 100%;
  filter: drop-shadow(5px 5px 25px rgba(0, 0, 0, 0.3));
}

.hero-circle:nth-child(3) {
  position: absolute;
  left: 7%;
  top: 25%;
  height: 10vw;
  width: 10vw;
  background: var(--main-color);
  border-radius: 50%;
  z-index: 0;
  animation: 0.6s coolspawn ease-in-out;
}

.hero .info {
  z-index: 2;
  animation: 0.6s coolspawn ease-in-out;
}

.hero h2 {
  width: 600px;
  font-weight: 700;
  font-size: 30px;
  color: white;
}

.hero h1 {
  font-family: "bernard-mt", "SF Pro Display", "SF Pro Icons", "Helvetica Neue",
    "Helvetica", "Arial", sans-serif;
  font-size: 110px;
  line-height: 70px;
  color: #a6ffb2;
  font-weight: 500;
}

.hero h3 {
  font-weight: 700;
  font-size: 20px;
  color: white;
}

.hero button {
  height: 70px;
  width: 250px;
  margin-top: 50px;
  border: none;
  border-radius: 35px;
  background-color: var(--main-color);
  transition: 0.25s;
  font-size: 26px;
  color: white;
}

.hero button:hover {
  background-color: var(--main-color-dark);
}

/*CALL BUTTON*/

.callBtn{
  z-index: 9999;
  position: fixed !important;
  width: 100px;
  height: 100px;
  border-radius: 100% !important;
  -webkit-appearance: none;
  cursor: pointer;
  border:none;
  background-color: transparent;
  background-repeat: no-repeat;
  overflow: hidden;
  outline: none;
  right: 30px;
  bottom: 30px;
  background-color: #a6ffb2;
  filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.5));
}

.callBtn:hover {
  background-color:#86cb8f;
  transform: scale(0.95);
}

.callBtn img {
  width: 70%;
  height: 70%;
  filter: invert(1) drop-shadow(0 0 15px black);
}


/*MOBILE CALL BUTTON*/

.mobCallBtn{
  z-index: 999;
  display: none;
  position: fixed !important;
  width: 100px;
  height: 100px;
  right: 30px;
  bottom: 30px;
  border-radius: 100% !important;
  background-color: #a6ffb2;
  filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.5));
}

.mobCallBtn a {
  display: flex;
  height: 100%;
  width: 100%;
  margin: 0;

  align-items: center;
  justify-content: center;
}

.mobCallBtn:hover {
  background-color:#86cb8f;
  transform: scale(0.95);
}

.mobCallBtn img {
  width: 70%;
  height: 70%;
  filter: invert(1) drop-shadow(0 0 15px black);
}

@media screen and (max-width: 930px){
  .callBtn{
    display: none;
  }
  .mobCallBtn{
    display: block;
  }
}

@media screen and (min-width: 1536px) {
  .hero {
    margin-top: 50px;
    margin-bottom: 100px;
  }

  .hero h2 {
    font-size: 32px;
  }

  .hero h3 {
    font-size: 24px;
  }

  .hero button {
    width: 350px;
    height: 90px;
    border-radius: 45px;
    font-size: 32px;
  }
}

@media screen and (max-width: 1290px) {
  .hero {
    height: 400px;
    margin-bottom: 200px;
  }

  .hero h1 {
    font-size: 90px;
    line-height: 60px;
  }

  .hero-circle:nth-child(2) {
    height: 35vw;
    width: 35vw;
    left: 55%;
    top: 35%;
  }

  .hero-circle:nth-child(3) {
    left: 12%;
  }
}

@media screen and (max-width: 1024px) {
  .hero {
    height: 300px;
    width: 80%;
    margin-bottom: 200px;
  }

  .hero .info {
    width: 100%;
  }

  .hero h1 {
    font-size: 10vw;
    line-height: 35px;
  }

  .hero h2 {
    width: 100%;
  }

  .hero-circle:nth-child(2) {
    height: 40vw;
    width: 40vw;
    left: 55%;
    top: 35%;
  }

  .hero-circle:nth-child(3) {
    left: 10%;
  }

  .callBtn, .mobCallBtn {
    width: 75px;
    height: 75px;
  }
}

@media screen and (max-width: 768px) {
  .hero h2 {
    font-size: 3vw;
  }

  .hero h1 {
    line-height: 15px;
  }

  .hero h3 {
    font-size: 2vw;
  }

  .hero button {
    width: 150px;
    font-size: 20px;
    height: 50px;
  }

  .hero-circle:nth-child(2) {
    top: 40%;
  }
}

@media screen and (max-width: 426px) {
  .hero {
    display: flex;
    flex-direction: column;
    height: 150px;
  }

  .hero .info {
    text-align: center;
  }

  .hero br {
    height: 0;
  }

  .hero h2 {
    font-size: 4vw;
    margin: 0 !important;
  }

  .hero h1 {
    font-size: 12vw;
    line-height: 5px;
    margin: 0 !important;
  }

  .hero h3 {
    font-size: 2.5vw;
    margin: 0 !important;
  }

  .hero button {
    margin-top: 20px;
  }

  .hero-circle:nth-child(2) {
    position: static;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 55vw;
    width: 55vw;
  }

  .hero-circle:nth-child(2) img {
    position: static;
  }

  .hero-circle:nth-child(3) {
    display: none;
  }
}