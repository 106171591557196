footer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

footer > img {
  margin: 60px;
  width: 20%;
}

footer h4 {
  color: var(--main-color);
  margin-bottom: 20px;
  text-align: center;
}

footer .line {
  margin: 20px 0;
  width: 80%;
  height: 3px;
  background-color: var(--main-color);
}

footer .options {
  width: 80%;
  display: flex;
  justify-content: space-evenly;
  margin: 60px 0;
}

footer .navigation {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

footer .option {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 20px;
  font-weight: 400;
  color: white;
}

footer .option .underline {
  margin-top: 2px;
  height: 2px;
  width: 0px;
  background-color: white;
}

footer .option:hover .underline {
  width: 100%;
}

footer .contacts {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

footer .contacts .option {
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 18px;
}

.social-media {
  width: fit-content;
  height: fit-content;
}

.social-media a {
  margin: 0 5px;
  filter: invert(1);
}

.social-media img {
  height: 40px;
  /*width: 40px;*/
}

.social-media a:hover {
  filter: invert(1) brightness(80%);
}
.mapLink{
  color: #ffffff;
}
.copyright{
  font-family: "SF Pro Display","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;
  text-align: center;
  font-weight: 500;
  font-size: 10pt;
  color: #AAAAAA;
  text-align: center;
  letter-spacing: .004em;
  margin-bottom: 20px;
}
.mail{
  color: var(--main-color);
}
.dmst-logo{
  display: block;
}
.dmst-logo img{
  height: 66px;
  position: absolute;
  z-index: 999;
  left: 10%;
}
.socdmst{
  display: none;
}
@media screen and (max-width: 1080px) {
  footer > img {
    width: 30%;
  }
  .socdmst{
    display: inline-block;
  }
  .dmst-logo{
    display: none;
  }
}

@media screen and (max-width: 425px) {
  footer > img {
    width: 50%;
  }

  footer .options {
    width: 90%;
  }

  footer .option {
    font-size: 14px;
  }



}

